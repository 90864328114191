<template>
    <div>
        <!--面包屑导航-->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>学校概况</el-breadcrumb-item>
            <el-breadcrumb-item>校长寄语</el-breadcrumb-item>
        </el-breadcrumb>
        <!--卡片区域-->
        <el-card>
            <div class="diveditor">
<!--                <quilleditor-widget ref="quillView" :details="info.content"></quilleditor-widget>-->
                <ueditor-wrap v-if="finish" :details="info.content" ref="quillView" typeStr="school"></ueditor-wrap>
            </div>
<!--            <div class="diveditor">-->
<!--                <quilleditor-widget ref="quillView" :details="info.content"></quilleditor-widget>-->
<!--            </div>-->
            <div class="btneditor">
                <el-button type="primary" @click="preserve" :loading="btnLoading">保 存</el-button>
            </div>
        </el-card>
    </div>
</template>

<script>
    import QuilleditorWidget from '../common/quill_editor_widget.vue';
    import UeditorWrap from '../common/ueditor_wrap.vue';
    export default {
        name: 'Sendword',
        data() {
            return {
                btnLoading:false,
                info:{},//详情
                finish: false
            }
        },
        components:{
            QuilleditorWidget,
            UeditorWrap
        },
        mounted () {
            this.getDetailes();
        },
        methods: {
            //获取详情
            async getDetailes(){
                const { data: res } = await this.$http.get('/schoolContent/detail', {params:{type:'3'}})
                this.finish = true;
                if (res.code !== 200) {
                    this.$message.error(res.message)
                } else {
                    if(res.result){
                        this.info = res.result;
                    }
                }
            },
            async preserve(){
                if(this.$refs.quillView.content == ''){
                    return this.$message.error('请输入内容')
                }
                this.btnLoading = true;
                let result = null;
                if(this.info.id) {//编辑
                    //result = await this.$http.put('/schoolContent/edit', {id:this.info.id,content:this.$refs.quillView.content,type:'3'})
                    result = await this.$http({
                      url: '/schoolContent/edit',
                      data: {id:this.info.id,content:this.$refs.quillView.content,type:'3'},
                      method:'put',
                      timeout: 120000
                    })
                }else {//添加
                    //result = await this.$http.post('/schoolContent/add', {content:this.$refs.quillView.content,type:'3'})
                    result = await this.$http({
                        url: '/schoolContent/add',
                        data: {content:this.$refs.quillView.content,type:'3'},
                        method:'post',
                        timeout: 120000
                    })
                }
                let res = result.data;
                if (res.code !== 200) {
                    this.btnLoading = false;
                    this.$message.error(res.message)
                } else {
                    this.btnLoading = false;
                    this.$message.success('操作成功');
                    this.getDetailes();
                }
            },
        }
    }
</script>

<style scoped>
    .btneditor{margin-top:16px;text-align:center;}
    .btneditor .el-button{width:96px;}
</style>
